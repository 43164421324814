<template>
	<w-layout align-center justify-space-between row>
		<w-flex shrink>
			<w-btn :flat="!onlyCurrentProjects" icon="today" :outline="!onlyCurrentProjects" mini small @click="onlyCurrentProjects = !onlyCurrentProjects">
				{{ onlyCurrentProjects ? $t('projects.show_every_projects') : $t('projects.only_show_current_projects') }}
			</w-btn>
		</w-flex>
		<w-flex mx-1>
			<w-layout row>
				<w-flex xs6 mr-1>
					<SelectWithChips
						v-model="searchedCategories"
						clearable
						dense
						:items="categories"
						item-text="name"
						item-value="id"
						:label="$tc('projects.categories', 2)"
						list-with-chips
						:loading="isCategoryListLoading"
						:max-selected-items-showed="2"
						multiple
						prepend-inner-icon="color_lens"
					/>
				</w-flex>
				<w-flex xs6 mr-1>
					<SelectWithChips
						v-model="searchedStatuses"
						clearable
						dense
						:items="statuses"
						item-text="name"
						item-value="id"
						:label="$tc('projects.statuses', 2)"
						list-with-chips
						:loading="isStatusListLoading"
						:max-selected-items-showed="2"
						multiple
						prepend-inner-icon="play_arrow"
					/>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'

import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'CustomersManagerOffersFilters',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	computed: {
		...mapState({
			categories: state => state.customers.projects.categories,
			statuses: state => state.customers.projects.statuses
		}),
		isCategoryListLoading: function () {
			return this.$store.state.customers.projects.loaders['listCategories']
		},
		isStatusListLoading: function () {
			return this.$store.state.customers.projects.loaders['listStatuses']
		},
		onlyCurrentProjects: {
			get: function () {
				return this.$store.state.customers.projects.filters.onlyCurrentProjects
			},
			set: function (value) {
				this.$store.dispatch('customers/projects/setOnlyCurrentProjects', value)
			}
		},
		searchedCategories: {
			get: function () {
				return this.$store.state.customers.projects.filters.categories
			},
			set: function (val) {
				this.$store.dispatch('customers/projects/setSearchedCategories', val)
			}
		},
		searchedStatuses: {
			get: function () {
				return this.$store.state.customers.projects.filters.statuses
			},
			set: function (newVal) {
				this.$store.dispatch('customers/projects/setSearchedStatuses', newVal)
			}
		}
	}
}
</script>
